export const DEFAULT_IMAGE_ICON_SIZE = 33;

export const THUMBNAIL_WIDTH = 60;

export const NUMBER_OF_OPTIONS_TO_SHOW_BEFORE_COLLAPSE = 0;

export const MAX_ITEM_OPTION_LENGTH = 500;

export const SPECS = {
  AddSlotToCheckout: 'specs.stores.AddSlotToCheckout',
  DontRedirectToSiteOnFailedFetch: 'specs.stores.DontRedirectToSiteOnFailedFetch',
  CheckoutExcludeGoogleAutoComplete: 'specs.stores.CheckoutExcludeGoogleAutoComplete',
  ShouldFetchCustomSettingsAndCustomizeCheckoutLayout:
    'specs.stores.ShouldFetchCustomSettingsAndCustomizeCheckoutLayout',
  ShouldUsePanorama: 'specs.stores.ShouldUsePanorama',
  ShouldSplitBillingInfoPrefill: 'specs.stores.ShouldSplitBillingInfoPrefill',
  SlotSpacingAndDividers: 'specs.stores.SlotSpacingAndDividers',
  HideBillingFormForPayPalAndManualNotBrazil: 'specs.stores.HideBillingFormForPayPalAndManualNotBrazil',
  EnablePlaceOrderButtonForExistingNoAddressCustomer: 'specs.stores.EnablePlaceOrderButtonForExistingNoAddressCustomer',
  WithCheckoutHackForReact18Tests: 'specs.stores.WithCheckoutHackForReact18Tests',
  GiftCartSeviceDownCheckout: 'specs.stores.GiftCartSeviceDownCheckout',
  ShowErrorForMissingZipcode: 'specs.stores.ShowErrorForMissingZipcode',
  UseUndefinedAsDefaultBillingAddressInCheckout: 'specs.stores.UseUndefinedAsDefaultBillingAddressInCheckout',
  MoveSlotAboveCheckoutButton: 'specs.stores.MoveSlotAboveCheckoutButton',
  ShowExpressCheckoutButtonsOnInitialStep: 'specs.stores.ShowExpressCheckoutButtonsOnInitialStep',
  FixSetAsDefaultAddress: 'specs.stores.FixSetAsDefaultAddress',
  UseCheckoutHeaderOnCheckout: 'specs.stores.UseCheckoutHeaderOnCheckout',
  ShowVatIdOnBillingForIsrael: 'specs.stores.ShowVatIdOnBillingForIsrael',
  SendTrackEventWhenCheckoutLoad: 'specs.stores.SendTrackEventWhenCheckoutLoad',
  UiFixForCheckoutHeader: 'specs.stores.UiFixForCheckoutHeader',
  AddLayoutConfigToLineItemsAutoGql: 'specs.stores.AddLayoutConfigToLineItemsAutoGql',
  ShouldAddLocaleToDatePicker: 'specs.stores.ShouldAddLocaleToDatePicker',
  ShouldHandlePaylinksRedirectError: 'specs.stores.ShouldHandlePaylinksRedirectError',
  ShouldFocusCheckboxesOnError: 'specs.stores.ShouldFocusCheckboxesOnError',
  ShouldRedesignCouponsSection: 'specs.stores.ShouldRedesignCouponsSection',
  FixSSRInCheckout: 'specs.stores.FixSSRInCheckout',
  shouldChangeToBiCatalogV2: 'specs.stores.shouldChangeToBiCatalogV2',
  UseNewSubscriptionCheckboxInCustomerDetailsForm: 'specs.stores.UseNewSubscriptionCheckboxInCustomerDetailsForm',
  MigrateSubscriptionToCheckoutForm: 'specs.stores.MigrateSubscriptionToCheckoutForm',
  ChangeCheckoutFlowTagName: 'specs.stores.ChangeCheckoutFlowTagName',
  shouldFixSSRInMobileFlicker: 'specs.stores.shouldFixSSRInMobileFlicker',
  FixCustomPolicyLongText: 'specs.stores.FixCustomPolicyLongText',
  ShouldCheckViolationsOnUpdateCheckout: 'specs.stores.ShouldCheckViolationsOnUpdateCheckout',
  AddSlotToCheckoutPolicies: 'specs.stores.AddSlotToCheckoutPolicies',
  RemoveOpacityGetFunctions: 'specs.stores.RemoveOpacityGetFunctions',
};
